var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:{
    'stake-item': true,
    'stake-item--active': _vm.stakeItem.isActive,
  }},[_c('div',{class:{
      'stake-item__content': true,
      'stake-item__content--active': _vm.stakeItem.isActive,
    }},[_c('div',{staticClass:"stake-item__content-child-container"},[_c('span',{staticClass:"stake-item__row-title"},[_vm._v(" Token ")]),_c('div',{staticClass:"stake-item__token"},[(_vm.stakeItem.chain === 'bsc')?_c('img',{staticClass:"stake-item__token-icon",attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/tcg_bnb_coin_icon.png"}}):_c('img',{staticClass:"stake-item__token-icon",attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/tcg_eth_coin_icon.png"}}),_vm._m(0)]),_c('div')]),_c('div',{staticClass:"\n        stake-item__content-child-container\n        stake-item__content-child-container--apy--container\n      "},[_c('div',{staticClass:"stake-item__apy-content"},[_c('span',{staticClass:"stake-item__row-title"},[_vm._v(" APY ")]),_c('span',{staticClass:"\n            stake-item__text-value\n            stake-item__text-value--color--green\n            mobile--text--center\n          "},[_vm._v(" "+_vm._s(_vm.isExpired ? '--' : _vm.checkApy(_vm.stakeItem.apy))+" ")]),_c('div')]),_c('button',{staticClass:"stake-item__details-btn stake-item__details-btn--mobile",on:{"click":function($event){$event.preventDefault();return _vm.toggleStakeItemActiveState()}}},[_c('img',{class:{
            'stake-item__details-btn-icon': true,
            'stake-item__details-btn-icon--active': _vm.stakeItem.isActive,
          },attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/chevron-down.png"}})])]),_c('div',{staticClass:"stake-item__rates-container"},[_c('div',{staticClass:"\n          stake-item__content-child-container\n          stake-item__content-child-container--rate--child\n        "},[_c('span',{staticClass:"stake-item__row-title"},[_vm._v(" Reward Tokens (TCG2) ")]),_c('span',{staticClass:"stake-item__text-value"},[_vm._v(" "+_vm._s(_vm.getNumberWithCommas(_vm.stakeItem.total_rewards))+" ")]),_c('div')]),_c('div',{staticClass:"\n          stake-item__content-child-container\n          stake-item__content-child-container--rate--child\n        "},[_c('span',{staticClass:"stake-item__row-title"},[_vm._v(" Global Max Stake (TCG2) ")]),_c('span',{staticClass:"stake-item__text-value"},[_vm._v(" "+_vm._s(_vm.getNumberWithCommas(_vm.checkZero(_vm.stakeItem.stake_limit_global, 1)))+" ")]),_c('div')]),_c('div',{staticClass:"\n          stake-item__content-child-container\n          stake-item__content-child-container--rate--child\n        "},[_c('span',{staticClass:"stake-item__row-title"},[_vm._v(" Total Staked (TCG2) ")]),_c('span',{staticClass:"stake-item__text-value"},[_vm._v(" "+_vm._s(_vm.getNumberWithCommas(_vm.stakeItem.total_staked))+" ")]),_c('div')])]),_c('div',{staticClass:"stake-item__content-child-container mobile--disabled"},[_c('div'),_c('button',{staticClass:"stake-item__details-btn",on:{"click":function($event){$event.preventDefault();return _vm.toggleStakeItemActiveState()}}},[_c('span',{staticClass:"stake-item__details-btn-label"},[_vm._v(" "+_vm._s(_vm.stakeItem.isActive ? "Hide" : "Details")+" ")]),_c('img',{class:{
            'stake-item__details-btn-icon': true,
            'stake-item__details-btn-icon--active': _vm.stakeItem.isActive,
          },attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/chevron-down.png"}})]),_c('div')]),_c('div',{staticClass:"\n        stake-item__content-child-container\n        stake-item__content-child-container--status--container\n      "},[_c('div',{class:[
          'stake-item__content-status',
          ("stake-item__content-status--" + (_vm.stakeItem.status.toLocaleLowerCase()))
        ]},[_c('span',{staticClass:"stake-item__content-status-text"},[_vm._v(" "+_vm._s(_vm.getNormilizedStatus(_vm.stakeItem.status))+" ")])]),(_vm.stakeItem.status === 'STARTING_SOON')?_c('span',{staticClass:"stake-item__status-start-date"},[_vm._v(" "+_vm._s(_vm.getNormilizedDateFormat(_vm.stakeItem.start_date))+" ")]):_vm._e()]),_c('div',{staticClass:"\n        stake-item__content-child-container\n        stake-item__content-child-container--status--container\n      "},[_c('div',{staticClass:"stake-item__status-staked"},[(_vm.stakeItem.user_data)?_c('CoinsIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('In this pool, you currently have staked tokens.'),expression:"'In this pool, you currently have staked tokens.'"}]}):_c('div',{staticStyle:{"width":"1rem","height":"1rem"}})],1)])]),_c('div',{ref:"stakeDetailsElem",staticClass:"stake-item__details"},[_c('StakeItemDetails',{attrs:{"stakeItem":_vm.stakeItem,"idx":_vm.idx},on:{"show-connect-popup":function (idx) { return _vm.showConnectPopup(idx); },"show-switch-chain-popup":function (idx) { return _vm.showSwitchChainPopup(idx); },"show-confirm-popup":function (idx) { return _vm.showConfirmPopup(idx); },"show-withdraw-popup":function (idx) { return _vm.showWithdrawPopup(idx); },"show-harvest-popup":function (idx) { return _vm.showClaimPopup(idx); },"stake-expired":function (idx) { return _vm.onStakeExpired(idx); }}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stake-item__token-text"},[_c('span',{staticClass:"stake-item__token-name"},[_vm._v("TCG Coin 2.0")]),_c('span',{staticClass:"stake-item__token-subname"},[_vm._v(" Stake and Earn TCG Coin ")])])}]

export { render, staticRenderFns }