import { ref } from "@vue/composition-api";

class Popup {
  readonly isVisible = ref(false);

  close(callback?: (...params: any) => any | void, ...args: any[]): any {
    this.isVisible.value = false;

    if (typeof callback === "function") {
      return callback(...args);
    }

    return null;
  }

  show(callback?: (...params: any) => any | void, ...args: any[]): any {
    this.isVisible.value = true;

    if (typeof callback === "function") {
      return callback(...args);
    }

    return null;
  }

  toggle(callback?: (...params: any) => any | void, ...args: any[]): any {
    this.isVisible.value = !this.isVisible.value;

    if (typeof callback === "function") {
      return callback(...args);
    }

    return null;
  }
}

export default Popup;
